import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useHistory
} from "react-router-dom";
import { Routes } from "../../../app/route/RoutesConfig";
import { UserUtils } from "../../../app/data/user/userUtils";
import ContentContainer from "../../../templates/content-container/contentContainer";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Table from "../../../ui-components/table/table";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import {
  getRecords,
  recordsSelector,
  resetRecordsState  
} from "../../../slices/delivery-records/recordsSlice";
import RecordsState from "../../../slices/delivery-records/RecordsState";
import RecordDetails from "./deliveryRecordDetails";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import "./deliveryRecords.scss";

const DeliveryRecordsList: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const recordsState: RecordsState = useSelector(recordsSelector);
  const userState: UserState = useSelector(userSelector);
  const [recordId, setRecordId] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [probill, setProbill] = useState<string>("");
  const [manifest, setManifest] = useState<string>("");

  const columns = [
    {
      Header: "Probill #",
      accessor: "probill",
      width: 110,
      Cell: (cellProps: any) => (
        <>
          {!UserUtils.isXGSDriver(userState.profile) && (
            <Link
              className="xgs-table-link-cell"
              target="_blank"
              to={`/shipments/${cellProps.value}`}
              onClick={(e) => e.stopPropagation()}
            >
              {cellProps.value}
            </Link>
          )}
          {UserUtils.isXGSDriver(userState.profile) && (
            <>
              {cellProps.value}
            </>
          )}
        </>
      )
    },
    {
      width: 120,
      Header: "Manifest #",
      accessor: "manifest"
    },
    {
      Header: "Shipper",
      accessor: "shipper",
      width: 290,
      Cell: (cellProps: any) => (
        <>
          {cellProps.value && (
            <>
              { cellProps.value.info } <span className="xgs-delivery-records__details__account-number">{cellProps.value.number}</span>
            </>
          )}
        </>
      )
    },
    {
      Header: (
        <div className="text-center">
          Number of photos
        </div>
      ),
      accessor: "items",
      width: 120,
      Cell: (cellProps: any) => (
        <div className="text-center">
          { cellProps.value.length }
        </div>
      )
    },
    {
      Header: (
        <div className="text-center">
          Date
        </div>
      ),
      id: "dateCreated",
      accessor: "dateCreated",
      width: 120,
      Cell: (cellProps: any) => (
        <div className="text-center">
          { cellProps.value.toUiDateFormat() }
        </div>
      )
    },
    {
      Header: (
        <div className="text-center">
          Time
        </div>
      ),
      id: "timeCreated",
      accessor: "dateCreated",
      width: 100,
      Cell: (cellProps: any) => (
        <div className="text-center">
          { cellProps.value.split("T")[1] }
        </div>
      )
    }
  ];
  
  const onRecordsInfiniteScroll = () => {
    dispatch(getRecords(getSearchRequestModel(), recordsState.recordsRequest.lastIds));
  };

  const onRecordClick = (row: any) => {
    setRecordId(row.id);
  };

  const onStartDateChanged = (value: string) => {
    setStartDate(value);
    const requestModel = getSearchRequestModel();
    requestModel.from = value?.toApiDateFormat();
    setRecordId(undefined);
    dispatch(getRecords(requestModel));
  };

  const onEndDateChanged = (value: string) => {
    setEndDate(value);
    const requestModel = getSearchRequestModel();
    requestModel.to = value?.toApiDateFormat();
    setRecordId(undefined);
    dispatch(getRecords(requestModel));
  };

  const getSearchRequestModel = () => {
    return {
      probill,
      manifest,
      from: startDate?.toApiDateFormat(),
      to: endDate?.toApiDateFormat()
    };
  };

  const searchRecords = () => {
    setRecordId(undefined);
    dispatch(getRecords(getSearchRequestModel()));
  };

  const clearSearchRecords = () => {
    setRecordId(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    setProbill("");
    setManifest("");
    dispatch(getRecords({}));
  };

  useEffect(() => {
    dispatch(resetRecordsState());
    dispatch(getRecords({}));

    return () => {
      dispatch(resetRecordsState());
    }
  }, [dispatch]);

  return (
    <ContentContainer title="Images">
      <div className="xgs-delivery-records">
        <Button
          className="xgs-delivery-records__new-button"
          theme={ButtonThemes.blue}
          onClick={() => { history.push(Routes.deliveryRecords.new) }}
        >
          Upload new images
        </Button>
        <div className="xgs-list__controls">
          <div className="xgs-list__controls__search xgs-delivery-records__list__filters">
            <LabeledTextInput
              label="Probill:"
              labelMode={LabelModes.column}
              className="xgs-delivery-records__list__filters__input"
              value={probill}
              onChange={(e) => setProbill(e.currentTarget.value)}
              onKeyDown={(e) => (e.key === "Enter") && searchRecords()}
              type="text"
              placeholder="Probill"
            />
            <LabeledTextInput
              label="Manifest:"
              labelMode={LabelModes.column}
              className="xgs-delivery-records__list__filters__input"
              value={manifest}
              onChange={(e) => setManifest(e.currentTarget.value)}
              onKeyDown={(e) => (e.key === "Enter") && searchRecords()}
              type="text"
              placeholder="Manifest"
            />
            <LabeledDateRangeInput
              label="Dates range:"
              labelMode={LabelModes.column}
              className="xgs-delivery-records__list__filters__dates"
              start={startDate}
              end={endDate}
              onStartChange={onStartDateChanged}
              onEndChange={onEndDateChanged}
            />
            <Button
              className="xgs-delivery-records__list__filters__button"
              theme={ButtonThemes.blue}
              onClick={searchRecords}
            >
              Search
            </Button>
            <Button
              className="xgs-delivery-records__list__filters__button xgs-delivery-records__list__filters__button--last"
              theme={ButtonThemes.gray}
              onClick={clearSearchRecords}
            >
              Clear
            </Button>
          </div>
        </div>        
        {recordsState.requestFailed && (recordsState.requestCreator === "SET_RECORDS" || recordsState.requestCreator === "ADD_RECORDS") && (
          <XGSErrorMessage>{recordsState.requestError || "Error while obtaining the list of records"}</XGSErrorMessage>
        )}
        {!(recordsState.requestFailed && (recordsState.requestCreator === "SET_RECORDS" || recordsState.requestCreator === "ADD_RECORDS")) && (
          <>
            <Table
              isLoading={recordsState.requestStarted && (recordsState.requestCreator === "SET_RECORDS" || recordsState.requestCreator === "ADD_RECORDS")}
              columns={columns}
              data={recordsState.records}
              cursorPointer={true}
              onRowClicked={onRecordClick}
              rowHeight={42}
              minTableHeight={200}
              noResultsText="There are no images available"
              infiniteScroll={true}
              infiniteScrollLoading={recordsState.fetchPortionStarted}
              infiniteScrollHasNext={!recordsState.recordsFetchedAll}
              onInfiniteScroll={onRecordsInfiniteScroll}
              highlightRow={recordId ? recordsState.records.findIndex((record: any) => record.id === recordId) : null}
            />
            <RecordDetails
              id={recordId}
              show={!!recordId}
              onClose={() => {
                setRecordId(undefined);
              }}
            />
          </>
        )}
      </div>
    </ContentContainer>
  );
};

export default DeliveryRecordsList;
