import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import TeamsState from "../../../../slices/customers/teams/TeamsState";
import {
  teamsSelector,
  removeAccountFromTeamLocally
} from "../../../../slices/customers/teams/teamsSlice";
import TeamAccountState from "../../../../slices/customers/teams/TeamAccountState";
import {
  teamAccountSelector,
  removeAccountFromTeam
} from "../../../../slices/customers/teams/teamAccountSlice";
import modalStyle from "../../../../app/data/common/modalStyle"

Modal.setAppElement("#root");

export interface RemoveAccountProps {
  teamId: string;
  accountId: string;
  show: boolean;
  onAccountRemoved?: () => void;
  onClose: () => void;
}

const RemoveAccount: React.FC<RemoveAccountProps> = (props) => {
  const dispatch = useDispatch();
  const teamsState: TeamsState = useSelector(teamsSelector);
  const teamAccountState: TeamAccountState = useSelector(teamAccountSelector);

  const removeAccount = () => {
    if (!props.teamId || !props.accountId) return;
    dispatch(removeAccountFromTeam(props.teamId, props.accountId, () => {
      dispatch(removeAccountFromTeamLocally(props.accountId));
      toast.info("The account was removed from the customer!");
      props.onAccountRemoved && props.onAccountRemoved();
      props.onClose();
    }));
  };

  return (
    <Modal
      isOpen={props.show}
      style={modalStyle}
    >
      <div className="xgs-teams__modal__header">
        Remove account from the customer
      </div>
      <div className="xgs-teams__modal__content">
        <strong>{teamsState.accounts.find((account: any) => account.id === props.accountId)?.name}</strong> with account number <strong>{teamsState.accounts.find((account: any) => account.id === props.accountId)?.accountNumber}</strong> will be removed from the customer. All permissions for this account will be lost!
      </div>
      <div className="xgs-teams__modal__buttons">
        <Button
          theme={ButtonThemes.blue}
          className="xgs-teams__modal__button"
          onClick={props.onClose}
          type="button"
        >
          Cancel
        </Button>
        <Button
          theme={ButtonThemes.gray}
          className="xgs-teams__modal__button"
          spinner={teamAccountState.request_was_started}
          onClick={removeAccount}
          type="button"
        >
          Remove
        </Button>
      </div>
    </Modal>
  );
};

export default RemoveAccount;
