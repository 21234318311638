import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Form, Formik, FormikProps } from "formik";
import Modal from "react-modal";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Table from "../../../ui-components/table/table";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import PendingRequestsState from "../../../slices/customers/pending/PendingRequestsState";
import {
  approveShipperRequest,
  pendingRequestsSelector,
  searchAccounts
} from "../../../slices/customers/pending/pendingRequestsSlice";
import {
  ShipperApproveModel,
  ShipperApproveSchema
} from "../../../app/data/user/requestModels";
import { wideModalStyle } from "../../../app/data/common/modalStyle"
import "../../../sass/modal.scss";
import "../teams/teams.scss";
import "./pendingRequests.scss";

export interface ApproveRequestProps {
  requestId: string | undefined;
  requestData: any;
  show: boolean;
  onUpdate: () => void;
  onClose: () => void;
}

const approveInitialValues: ShipperApproveModel = {
  request: "",
  accountNumber: 0
}

const ShipperApproveModal: React.FC<ApproveRequestProps> = ({requestData, onClose, ...props}) => {
  const dispatch = useDispatch();
  const requestsState: PendingRequestsState = useSelector(pendingRequestsSelector);
  const [accountId, setAccountId] = useState<number>();
  const [accountSearchString, setAccountSearchString] = useState<string>("");
  const approveFormRef = useRef<any>(null);

  const accountsColumns = [
    {
      width: 90,
      minWidth: 90,
      Header: "Account #",
      accessor: "accountNumber"
    },
    {
      width: 240,
      minWidth: 200,
      Header: "Name",
      accessor: "accountName"
    },
    {
      width: 240,
      minWidth: 240,
      Header: "Address",
      address: "accounts",
      Cell: (cellProps: any) => (
        <div>
          {cellProps.row.original.address}, {cellProps.row.original.city}, {cellProps.row.original.state}, {cellProps.row.original.zip}
        </div>
      )
    }
  ];

  const onApprove = (data: ShipperApproveModel) => {
    dispatch(approveShipperRequest(data, () => {
      onClose();
      toast.info("The request was approved!");
      props.onUpdate();
    }));
  };

  const onAccountClick = (row: any) => {
    approveFormRef.current?.setFieldValue("accountNumber", row.accountNumber);
    setAccountId(row.accountId)
    setTimeout(() => {
      approveFormRef.current?.setErrors({});
    }, 50);
  };

  let accountsSearch = (searchStr: string) => {
    if (!searchStr || !String(searchStr).match(/\S+/) || searchStr.length < 2) return;
    approveFormRef.current?.setFieldValue("accountNumber", 0);
    setAccountId(0);
    dispatch(searchAccounts(searchStr));
    setTimeout(() => {
      approveFormRef.current?.setErrors({});
    }, 50);
  }

  useEffect(() => {
    if (!props.show) return;
    setTimeout(() => {
      approveFormRef.current?.setFieldValue("request", props.requestId);
    }, 50);
    if (requestData.shipperRequest.name) {
      setAccountSearchString(requestData.shipperRequest.name);
      accountsSearch(requestData.shipperRequest.name);
    } else {
      setAccountSearchString("");
      setTimeout(() => {
        const searchField = document.getElementById("xgs-pending-requests__team-search-field--js") as HTMLInputElement;
        if (searchField) searchField.focus();
      }, 280);
    }
    // eslint-disable-next-line
  }, [props.show]);

  return (
    <Modal
      isOpen={props.show}
      style={wideModalStyle}
    >
      <div className="xgs-pending-requests__details__modal">
        <div className="xgs-modal__header">
          Approve Request
        </div>
        <Formik
          onSubmit={onApprove}
          initialValues={approveInitialValues}
          validationSchema={ShipperApproveSchema}
          innerRef={approveFormRef}
          validateOnMount
          enableReinitialize
        >
          {(props: FormikProps<ShipperApproveModel>) => (
            <Form>
              <div className="xgs-pending-requests__details__modal">
                <div className="xgs-modal__content">
                  <div className="xgs-pending-requests__details__modal__step__header">Choose Account</div>
                  <div className="xgs-pending-requests__details__modal__step__content">
                    <input type="hidden" name="request" />
                    <div className="xgs-pending-requests__details__modal__team">
                      <div className="xgs-pending-requests__details__modal__search">
                        <LabeledTextInput
                          label="Account name or number:"
                          labelMode={LabelModes.column}
                          className="xgs-pending-requests__details__modal__search__input"
                          value={accountSearchString}
                          onChange={(e) => setAccountSearchString(e.currentTarget.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              !requestsState.request_was_started && accountsSearch(e.currentTarget.value);
                            }
                          }}
                          type="text"
                          placeholder="Customer, included account name or number"
                          id="xgs-pending-requests__team-search-field--js"
                        />
                        <Button
                          className="xgs-pending-requests__details__modal__search__button"
                          theme={ButtonThemes.blue}
                          disabled={requestsState.request_was_started || !accountSearchString || !String(accountSearchString).match(/\S+/)}
                          type="button"
                          onClick={() => accountsSearch(accountSearchString)}
                        >
                          Search
                        </Button>
                      </div>
                      {requestsState.request_was_failed && requestsState.request_creator === "ACCOUNT_SEARCH" && (
                        <XGSErrorMessage>{requestsState.request_fail_reason}</XGSErrorMessage>
                      )}
                      {!(requestsState.request_was_failed && requestsState.request_creator === "ACCOUNT_SEARCH") && (
                        <div className={requestsState.accounts.length > 1 ? "xgs-pending-requests__details__modal__table__wrapper-high" : "xgs-pending-requests__details__modal__table__wrapper-low"}>
                          <Table
                            isLoading={requestsState.request_was_started && requestsState.request_creator === "ACCOUNT_SEARCH"}
                            columns={accountsColumns}
                            data={requestsState.accounts}
                            cursorPointer={true}
                            onRowClicked={onAccountClick}
                            rowHeight={52}
                            noResultsText="No accounts found matching the specified criteria."
                            minTableHeight={200}
                            autoHeight={true}
                            infiniteScroll={true}
                            infiniteScrollLoading={false}
                            infiniteScrollHasNext={false}
                            onInfiniteScroll={() => {}}
                            highlightRow={accountId ? requestsState.accounts.findIndex((account: any) => account.accountId === accountId) : null}
                            highlightRowClass="xgs-pending-requests__details__modal__table__highlighted-row"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="xgs-modal__buttons text-center">
                  <Button
                    theme={ButtonThemes.gray}
                    onClick={() => {
                      approveFormRef.current?.setFieldValue("accountNumber", 0);
                      setAccountId(0);
                      onClose();
                    }}
                    className="xgs-modal__button"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    theme={ButtonThemes.blue}
                    className="xgs-modal__button"
                    disabled={!props.dirty || !props.values.accountNumber}
                    spinner={requestsState.request_was_started && requestsState.request_creator === "APPROVE"}
                  >
                    Approve
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ShipperApproveModal;
